<template>
  <div>
    <title>
      SIM INVENTORY ~ REPORTING MUTATION OF SCRAP
    </title>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        Reporting for Mutation of Scrap
        <br />
        <h4>
          The following is a list of reporting for mutation of scrap 
        </h4>
        <!-- <small>Control panel</small> -->
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Reporting</li>
      </ol>
    </section>

    <!-- Main content -->
    <section class="content">
      <!-- Small boxes (Stat box) -->
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-save"></i>
              <h3 class="box-title">Search Data</h3>
            </div>
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Start Date </label>
                  <input
                    type="date"
                    v-model="mulai"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">End Date </label>
                  <input
                    type="date"
                    v-model="selesai"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <button
                    type="button"
                    @click="searchdata()"
                    class="btn btn-info"
                  >
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div v-if="isSearch">
        <div class="row">
          <div class="col-md-12">
            <div class="box box-primary">
              <div class="box-header">
                <i class="fa fa-list"></i>
                <h3 class="box-title">
                  Reporting for Mutation of Scrap
                </h3>
              </div>
              <div v-if="isExist" class="box-body">
                <div class="table-responsive">
                  <table id="mydata" class="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>Item Code</th>
                        <th>Item Name</th>
                        <!-- <th>Type</th> -->
                        <th>Units</th>
                        <!-- <th>Category</th> -->
                        <th>Balance</th>
                        <th>In</th>
                        <th>Out</th>
                        <th>Ending Balance</th>
                        <th>Opname</th>
                        <th>Notes</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="classdata in list_paged"
                        :key="classdata.kode_barang"
                      >
                        <!-- <td>{{ classdata.id }}</td> -->
                        <td>{{ classdata.kode_barang }}</td>
                        <td>{{ classdata.nama_barang }}</td>
                        <!-- <td>{{ classdata.tipe }}</td> -->
                        <td>{{ classdata.unit }}</td>
                        <!-- <td>{{ classdata.kategori }}</td> -->
                        <td>{{ classdata.saldo }}</td>
                        <td>{{ classdata.masuk }}</td>
                        <td>{{ classdata.keluar }}</td>
                        <td>{{ classdata.saldo_akhir }}</td>
                        <td>{{ classdata.opnam }}</td>
                        <td>{{ classdata.keterangan }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-else class="box-body">
                <div class="table-responsive">
                  <table id="mydata" class="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>Item Code</th>
                        <th>Item Name</th>
                        <th>Type</th>
                        <th>Units</th>
                        <th>Category</th>
                        <th>Balance</th>
                        <th>In</th>
                        <th>Out</th>
                        <th>Ending Balance</th>
                        <th>Opname</th>
                        <th>Notes</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colspan="11">
                          <center>
                            <b style="color:red"> ... Data Not Found ... </b>
                          </center>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.row -->
      <!-- Main row -->
    </section>
  </div>
</template>

<script>
import swal from "sweetalert";
import axios from "axios";
export default {
  data() {
    return {
      list_paged: [],
      isExist: true,
      mulai: "",
      selesai: "",
      jenisbc: "",
      isSearch: false,
    };
  },
  created(){
    this.getnow()
  },
  methods: {
    getnow() {
      const today = new Date();
      const tahun = today.getFullYear();
      var month = today.getMonth();
      if (String(month).length == 1) {
        month = "0" + (month + 1);
      } else {
        month;
      }
      var tgl = today.getDate();
      if (String(tgl).length == 1) {
        tgl = "0" + tgl;
      } else {
        tgl;
      }
      // const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      this.mulai = tahun + "-" + month + "-" + tgl;
      this.selesai = tahun + "-" + month + "-" + tgl;
    },
    searchdata() {
      this.loading = true;
      this.isSearch = true;
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl +
        "laporan_bc/get_sp_mutasi_bc_scrap?tanggal_awal=" +
        this.mulai +
        "&tanggal_akhir=" +
        this.selesai;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          if (resp.data.pesan == "Data not found") {
            this.isExist = false;
            this.loading = false;
          } else {
            this.isExist = true;
            this.list_paged = resp.data.data;
            this.loading = false;
          }
        })
        .catch((err) => {
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          });
        });
    },
  },
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
